@tailwind base;
@tailwind components;
@tailwind utilities;


 /* Overall container styling */
 .fact-check {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    
}

/* Header styling */
.fact-check .result {
    font-size: 1.5em;
    font-weight: bold;
    color: #d9534f; /* Red for failure */
    margin-bottom: 20px;
}

/* Sources section styling */
.fact-check .sources {
    margin-bottom: 20px;
}

.fact-check .sources h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.fact-check .sources ul {
    list-style-type: none;
    padding: 0;
}

.fact-check .sources li {
    margin-bottom: 5px;
}

.fact-check .sources a {
    color: #0275d8; /* Blue links */
    text-decoration: none;
}

.fact-check .sources a:hover {
    text-decoration: underline;
}

/* Findings section styling */
.fact-check .findings {
    margin-bottom: 20px;
}

.fact-check .findings h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.fact-check .findings ul {
    list-style-type: none;
    padding: 0;
}

.fact-check .findings li {
    margin-bottom: 15px;
}

.fact-check .findings .claim-text {
    font-weight: bold;
}

.fact-check .findings .accuracy {
    font-style: italic;
    color: #5bc0de; /* Blue for accurate */
}

.fact-check .findings .explanation {
    margin-top: 5px;
    font-size: 0.9em;
    color: #333;
}

/* Analysis section styling */
.fact-check .analysis h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.fact-check .analysis p {
    font-size: 1em;
    color: #333;
}